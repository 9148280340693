import {
  type ResultWithOauthToken,
  type AppError,
  type Result,
  InvalidTokenError,
} from '@packages/utils';
import {
  useInfiniteQuery,
  type UseQueryResult,
  useQuery,
  type UseInfiniteQueryResult,
  type UseQueryOptions,
} from '@tanstack/react-query';
import {type z} from 'zod';
import {parseCookies} from 'nookies';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';
import {
  GraduationYearsHotResponse,
  MasterGroupedJobTypesResponse,
  RecruitmentListSchema,
  RecruitmentRankingsSchema,
  RecruitmentTagsResponse,
  RecruitmentTypesResponse,
  type RecruitmentSchema,
} from '../entities/recruitment/schema';
import {sendApiRequest} from '../usecases/auth/sendApiRequest';
import {useIsSessionExpired} from '../usecases/auth/useIsSessionExpired';

/**
 * GetGraduationYearsHot
 */
export type GraduationYearsHotReturnValueType = Result<
  GraduationYearsHotResponse,
  AppError
>;

export type GraduationYearsHotResponse = z.infer<
  typeof GraduationYearsHotResponse
>;

type GetGraduationYearsHotArgs = {
  version?: string;
};

type UseGraduationYearsHotOptions = {
  config?: UseQueryOptions<GraduationYearsHotReturnValueType>;
} & GetGraduationYearsHotArgs;

export const getGraduationYearsHot = async ({
  version = DEFAULT_API_VERSION,
}: GetGraduationYearsHotArgs): Promise<GraduationYearsHotReturnValueType> => {
  const path = `/${version}/graduation-years/hot`;
  const result = await defaultClient.get(path, GraduationYearsHotResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useGraduationYearsHot = ({
  config,
}: UseGraduationYearsHotOptions) => {
  return useQuery<GraduationYearsHotReturnValueType>({
    ...config,
    queryKey: ['GraduationYears'],
    async queryFn() {
      return getGraduationYearsHot({});
    },
  });
};

/**
 * RecruitmentTags
 */
export type RecruitmentTagsReturnValueType = Result<
  RecruitmentTagsResponse,
  AppError
>;

export type RecruitmentTagsResponse = z.infer<typeof RecruitmentTagsResponse>;

type GetRecruitmentTagsArgs = {
  version?: string;
};

type UseRecruitmentTagsOptions = {
  config?: UseQueryOptions<RecruitmentTagsReturnValueType>;
} & GetRecruitmentTagsArgs;

export const getRecruitmentTags = async ({
  version = DEFAULT_API_VERSION,
}: GetRecruitmentTagsArgs): Promise<RecruitmentTagsReturnValueType> => {
  const path = `/${version}/recruitment/tags`;
  const result = await defaultClient.get(path, RecruitmentTagsResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useRecruitmentTags = ({config}: UseRecruitmentTagsOptions) => {
  return useQuery<RecruitmentTagsReturnValueType>({
    ...config,
    queryKey: ['RecruitmentTags'],
    async queryFn() {
      return getRecruitmentTags({});
    },
  });
};

/**
 * GetRecruitmentTypes
 */
export type RecruitmentTypesReturnValueType = Result<
  RecruitmentTypesResponse,
  AppError
>;

export type RecruitmentTypesResponse = z.infer<typeof RecruitmentTypesResponse>;

type GetRecruitmentTypesArgs = {
  version?: string;
};

type UseRecruitmentTypesOptions = {
  config?: UseQueryOptions<RecruitmentTypesReturnValueType>;
} & GetRecruitmentTypesArgs;

export const getRecruitmentTypes = async ({
  version = DEFAULT_API_VERSION,
}: GetRecruitmentTypesArgs): Promise<RecruitmentTypesReturnValueType> => {
  const path = `/${version}/recruitment-types`;
  const result = await defaultClient.get(path, RecruitmentTypesResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useRecruitmentTypes = ({config}: UseRecruitmentTypesOptions) => {
  return useQuery<RecruitmentTypesReturnValueType>({
    ...config,
    queryKey: ['RecruitmentTypes'],
    async queryFn() {
      return getRecruitmentTypes({});
    },
  });
};

/**
 * GetMasterGroupedJobTypes
 */
export type MasterGroupedJobTypesReturnValueType = Result<
  MasterGroupedJobTypesResponse,
  AppError
>;

export type MasterGroupedJobTypesResponse = z.infer<
  typeof MasterGroupedJobTypesResponse
>;

type GetMasterGroupedJobTypesArgs = {
  version?: string;
};

type UseMasterGroupedJobTypesOptions = {
  config?: UseQueryOptions<MasterGroupedJobTypesReturnValueType>;
} & GetMasterGroupedJobTypesArgs;

export const getMasterGroupedJobTypes = async ({
  version = DEFAULT_API_VERSION,
}: GetMasterGroupedJobTypesArgs): Promise<MasterGroupedJobTypesReturnValueType> => {
  const path = `/${version}/master/grouped-job-types`;
  const result = await defaultClient.get(path, MasterGroupedJobTypesResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useMasterGroupedJobTypes = ({
  config,
}: UseMasterGroupedJobTypesOptions) => {
  return useQuery<MasterGroupedJobTypesReturnValueType>({
    ...config,
    queryKey: ['MasterGroupedJobTypes'],
    async queryFn() {
      return getMasterGroupedJobTypes({});
    },
  });
};

/**
 * GetRecruitment
 */

type GetRecruitingInfoListArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  queryParams: {
    limit: number;
    page: number;
    industryCategoryIDs: number[];
    order: string;
    tagIDs: number[];
    typeIDs: number[];
    groupedJobTypeIDs: number[];
    gy: number;
    allgy?: string;
  };
};
export type Recruitment = z.infer<typeof RecruitmentSchema>;
export type GetRecruitmentListResponse = z.infer<typeof RecruitmentListSchema>;
export type ResultGetRecruitmentList = ResultWithOauthToken<
  GetRecruitmentListResponse,
  AppError
>;

type UseGetCompanyListOptions = {
  config?: UseQueryOptions<ResultGetRecruitmentList>;
} & Omit<GetRecruitingInfoListArgs, 'cookies'>;

function getRecruitmentFromPages(
  data: ResultGetRecruitmentList | undefined,
): GetRecruitmentListResponse | undefined {
  if (!data?.ok) return undefined;
  return data.value;
}

export const useInfiniteRecruitmentList = ({
  version = DEFAULT_API_VERSION,
  queryParams,
}: UseGetCompanyListOptions): UseInfiniteQueryResult<
  GetRecruitmentListResponse,
  AppError
> => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useInfiniteQuery(
    [`RecruitmentList`, queryParams],
    async ({pageParam = {page: 1, cacheID: ''}}) => {
      const cookies = parseCookies();
      const result = await getRecruitmentList({
        cookies,
        version,
        queryParams: {
          ...queryParams,
          page: pageParam.page,
        },
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return getRecruitmentFromPages(result);
    },
    {
      getNextPageParam: (lastPage) => ({
        page: lastPage?.nextPage ?? 0,
      }),
    },
  );
};

export const useInfiniteRecruitmentListForTop = ({
  version = DEFAULT_API_VERSION,
  queryParams,
}: UseGetCompanyListOptions): UseInfiniteQueryResult<
  GetRecruitmentListResponse,
  AppError
> => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useInfiniteQuery(
    [`RecruitmentList`, queryParams],
    async ({pageParam = {page: 1, cacheID: ''}}) => {
      const cookies = parseCookies();
      const result = await getRecruitmentList({
        cookies,
        version,
        queryParams: {
          ...queryParams,
          page: pageParam.page,
        },
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      if (!result.ok) return;

      // 学年不問の募集情報は除外する
      result.value.recruitments = result.value?.recruitments.filter(
        (recruitment) => recruitment.targetYear !== 0,
      );
      result.value.recruitments = result.value?.recruitments.slice(0, 10);
      return getRecruitmentFromPages(result);
    },
    {
      getNextPageParam: (lastPage) => ({
        page: lastPage?.nextPage ?? 0,
      }),
    },
  );
};

export const useRecruitmentList = ({
  version = DEFAULT_API_VERSION,
  queryParams,
}: UseGetCompanyListOptions): UseQueryResult<
  GetRecruitmentListResponse,
  AppError
> => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery([`RecruitmentList`, queryParams], async () => {
    const cookies = parseCookies();
    const data = await getRecruitmentList({
      cookies,
      version,
      queryParams,
    });
    return getRecruitmentFromPages(data);
  });
};

export const getRecruitmentList = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  queryParams,
}: GetRecruitingInfoListArgs): Promise<ResultGetRecruitmentList> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);

    const path = createPathWithQuery(
      `/${version}/recruitment`,
      {...queryParams},
      'none',
    );

    return defaultClient.get(path, RecruitmentListSchema);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    authRequired: false,
  });

  return result;
};

/**
 * GetRecruitmentRankings
 */
type GetRecruitmentRankingsArgs = {
  version?: string;
  cookies: Partial<Record<string, string>>;
  queryParams: {
    limit?: number;
  };
};

export type GetRecruitmentRankingsResponse = z.infer<
  typeof RecruitmentRankingsSchema
>;

export type ResultGetRecruitmentRankings = ResultWithOauthToken<
  GetRecruitmentRankingsResponse,
  AppError
>;

export const useGetRecruitmentRankings = ({
  version = DEFAULT_API_VERSION,
  queryParams,
}: Omit<GetRecruitmentRankingsArgs, 'cookies'>) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<ResultGetRecruitmentRankings>({
    queryKey: ['getRecruitmentRankings'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getRecruitmentRankings({
        cookies,
        version,
        queryParams,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

export const getRecruitmentRankings = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  queryParams,
}: GetRecruitmentRankingsArgs): Promise<ResultGetRecruitmentRankings> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const path = createPathWithQuery(
      `/${version}/recruitment/rankings`,
      {...queryParams},
      'none',
    );

    return defaultClient.get(path, RecruitmentRankingsSchema);
  };

  const result = await sendApiRequest({
    request,
    cookies,
  });

  return result;
};
