import {z} from 'zod';
import {GroupedJobType} from '../jobTypes/schema';
import {
  RECRUITMENT_CATEGORY,
  RECRUITMENT_DETAIL_RESOURCE_TYPE,
  RECRUITMENT_SCHEDULE_WARNING_CODE,
  RECRUITMENT_SCHEDULE_WARNING_CTRL,
  RECRUITMENT_TYPE_KEYS,
  RECRUITMENT_TYPE_LABELS,
} from './constants';

export const GetRecruitmentIdByDetailIdResponseSchema = z.object({
  recruitmentID: z.number(),
});
export type GetRecruitmentIdByDetailIdResponse = z.infer<
  typeof GetRecruitmentIdByDetailIdResponseSchema
>;

// [FYI] gsskt_backend/src/gsskt_api/domain/recruitment.goのRecruitTypeを参照
const RecruitmentType = z.object({
  key: z.union([
    z.literal(RECRUITMENT_TYPE_KEYS.INTERN),
    z.literal(RECRUITMENT_TYPE_KEYS.SELECTION),
    z.literal(RECRUITMENT_TYPE_KEYS.EVENT),
    z.literal(RECRUITMENT_TYPE_KEYS.JOIN_EVENT),
    z.literal(RECRUITMENT_TYPE_KEYS.GSSKT_EVENT),
    z.literal(RECRUITMENT_TYPE_KEYS.ETC),
  ]),
  label: z.union([
    z.literal(RECRUITMENT_TYPE_LABELS.INTERN),
    z.literal(RECRUITMENT_TYPE_LABELS.SELECTION),
    z.literal(RECRUITMENT_TYPE_LABELS.EVENT),
    z.literal(RECRUITMENT_TYPE_LABELS.JOIN_EVENT),
    z.literal(RECRUITMENT_TYPE_LABELS.GSSKT_EVENT),
    z.literal(RECRUITMENT_TYPE_LABELS.ETC),
  ]),
});
export type RecruitmentType = z.infer<typeof RecruitmentType>;

export type RecruitmentSchedulesScheme = {
  id: number;
  name: string;
  place: string;
  displayOrder: number;
  calendarRegisteredCount: number;
  isCalendarRegistered: boolean;
  applyMethod: {
    id: number;
    label: string;
  };
  entryStart: string;
  entryEnd: string;
  eventStart: string;
  eventEnd: string;
  isEntryStartAllDay: boolean;
  isEntryEndAllDay: boolean;
  isEventStartAllDay: boolean;
  isEventEndAllDay: boolean;
};

const RecruitmentTag = z.object({
  id: z.number(),
  longName: z.string(),
  shortName: z.string(),
  displayOrder: z.number(),
});
export type RecruitmentTag = z.infer<typeof RecruitmentTag>;

const RecruitmentSchedule = z.object({
  id: z.number(),
  name: z.string(),
  place: z.string(),
  displayOrder: z.number(),
  calendarRegisteredCount: z.number(),
  isCalendarRegistered: z.boolean(),
  applyMethod: z.object({
    id: z.number(),
    label: z.string(),
  }),
  entryStart: z.string(),
  entryEnd: z.string(),
  eventStart: z.string(),
  eventEnd: z.string(),
  isEntryStartAllDay: z.boolean(),
  isEntryEndAllDay: z.boolean(),
  isEventStartAllDay: z.boolean(),
  isEventEndAllDay: z.boolean(),
});
export type RecruitmentSchedule = z.infer<typeof RecruitmentSchedule>;

const RecruitmentCompany = z.object({
  id: z.number(),
  name: z.string(),
  industryCategoryID: z.number(),
  industry: z.string(),
  foundedYear: z.number(),
  employeeCount: z.number(),
  followerCount: z.number(),
  isFollowed: z.boolean(),
  isWishedIndustry: z.boolean(),
  isWishedJobType: z.boolean(),
  isNew: z.boolean(),
  catchPhrase: z.string(),
  capitalDescription: z.string(),
  pageType: z.string(),
  backgroundImageURLs: z.object({
    '300x126': z.string(),
    '640x268': z.string(),
    '948x392': z.string(),
  }),
  logoURLs: z.object({
    '140x105': z.string(),
    '200x150': z.string(),
    '400x300': z.string(),
  }),
  studyUrl: z.string(),
});

export const Recruitment = z.object({
  id: z.number(),
  detailID: z.number(),
  title: z.string(),
  category: z.string(),
  type: RecruitmentType,
  targetYear: z.number(),
  publishedAt: z.string(),
  postedAt: z.string(),
  displayEndAt: z.string(),
  schedules: z.array(RecruitmentSchedule),
  scheduleWarning: z.object({
    context: z.string(),
  }),
  tags: z.array(RecruitmentTag),
  groupedJobTypes: z.array(GroupedJobType),
  companies: z.array(RecruitmentCompany),
  _inactiveSchedules: z.array(z.any()), // エンドポイントのレスポンスの型が確定したら修正する
  entryURL: z.string(),
  detailPageURL: z.string(),
});
export type Recruitment = z.infer<typeof Recruitment>;

export const RecruitmentListResponseSchema = z.object({
  recruitments: z.array(Recruitment),
  totalCount: z.number(),
  nextPage: z.number(),
});
export type GetRecruitmentListResponse = z.infer<
  typeof RecruitmentListResponseSchema
>;

export type ScheduleDateObjType = {
  dayOfWeek: string;
  year: number;
  month: number;
  day: number;
  time: string;
};

export const GraduationYearsHotResponse = z.object({
  years: z.array(z.number()),
});

export const TagSchema = z.object({
  id: z.number(),
  longName: z.string(),
  shortName: z.string(),
  displayOrder: z.number(),
});

export const RecruitmentTagsResponse = z.object({
  tags: z.array(TagSchema),
});

export const TypeSchema = z.object({
  id: z.number(),
  name: z.string(),
  shortName: z.string(),
});

export const RecruitmentTypesResponse = z.object({
  types: z.array(TypeSchema),
});

export const MasterGroupedJobTypesSchema = z.object({
  id: z.number(),
  name: z.string(),
  categoryName: z.string(),
});

export const MasterGroupedJobTypesResponse = z.object({
  groupedJobTypes: z.array(MasterGroupedJobTypesSchema),
});

export const RecruitmentSchema = z.object({
  id: z.number(),
  detailID: z.number(),
  title: z.string(),
  category: z.string(),
  type: z.object({
    key: z.string(),
    label: z.string(),
  }),
  targetYear: z.number(),
  publishedAt: z.string(),
  postedAt: z.string(),
  displayEndAt: z.string(),
  schedules: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      place: z.string(),
      displayOrder: z.number(),
      calendarRegisteredCount: z.number(),
      isCalendarRegistered: z.boolean(),
      applyMethod: z.object({
        id: z.number(),
        label: z.string(),
      }),
      entryStart: z.string(),
      entryEnd: z.string(),
      eventStart: z.string(),
      eventEnd: z.string(),
      isEntryStartAllDay: z.boolean(),
      isEntryEndAllDay: z.boolean(),
      isEventStartAllDay: z.boolean(),
      isEventEndAllDay: z.boolean(),
    }),
  ),
  scheduleWarning: z.object({
    context: z.string(),
  }),
  tags: z.array(TagSchema),
  groupedJobTypes: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ),
  companies: z.array(RecruitmentCompany),
  _inactiveSchedules: z.array(z.unknown()),
  entryURL: z.string(),
  detailPageURL: z.string(),
});

const RecruitmentDetailSchedule = z.object({
  addCalendarUsers: z.number(),
  deadline: z.string(),
  entryEnd: z.string(),
  entryStart: z.string(),
  eventDate: z.string(),
  eventEnd: z.string(),
  eventStart: z.string(),
  hasAddCalendar: z.boolean(),
  id: z.number(),
  isEntryEndAllDay: z.boolean(),
  isEntryStartAllDay: z.boolean(),
  isEventEndAllDay: z.boolean(),
  isEventStartAllDay: z.boolean(),
  name: z.string(),
  place: z.string(),
});
export type RecruitmentDetailSchedule = z.infer<
  typeof RecruitmentDetailSchedule
>;

const RecruitmentScheduleWarning = z.object({
  code: z.enum([
    RECRUITMENT_SCHEDULE_WARNING_CODE.OK,
    RECRUITMENT_SCHEDULE_WARNING_CODE.UNDETERMINABLE,
    RECRUITMENT_SCHEDULE_WARNING_CODE.ENTRY_DEADLINE_OVER,
    RECRUITMENT_SCHEDULE_WARNING_CODE.EVENT_END_OVER,
  ]),
  ctrl: z.enum([
    RECRUITMENT_SCHEDULE_WARNING_CTRL.ALWAYS_ON,
    RECRUITMENT_SCHEDULE_WARNING_CTRL.ALWAYS_OFF,
    RECRUITMENT_SCHEDULE_WARNING_CTRL.DEPENDS_ON,
  ]),
  message: z.string(),
});
export type RecruitmentScheduleWarning = z.infer<
  typeof RecruitmentScheduleWarning
>;

const RecruitmentDetailCompany = z.object({
  id: z.number(),
  industryName: z.string(),
  logoImageURL: z.string(),
  mainImageURL: z.string(),
  name: z.string(),
  outline: z.string(),
  pageType: z.string(),
  page: z.object({
    industry: z.string(),
  }),
  title: z.string(),
});
export type RecruitmentDetailCompany = z.infer<typeof RecruitmentDetailCompany>;

const RecruitmentDetailResource = z.object({
  content: z.string(),
  contentType: z.string().optional(), // TODO: enumに変更
  displayOrder: z.number(),
  resourceID: z.number(),
  resourceType: z.enum([
    RECRUITMENT_DETAIL_RESOURCE_TYPE.CONTENT,
    RECRUITMENT_DETAIL_RESOURCE_TYPE.LINK,
  ]),
  title: z.string(),
});
export type RecruitmentDetailResource = z.infer<
  typeof RecruitmentDetailResource
>;

export const RecruitmentDetailResponseSchema = z.object({
  archiveID: z.number(),
  companies: z.array(RecruitmentDetailCompany),
  entryURL: z.string(),
  groupedJobTypes: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ),
  presenter: z.union([
    z.literal(RECRUITMENT_CATEGORY.ARCHIVE),
    z.literal(RECRUITMENT_CATEGORY.CLIENT),
  ]),
  resources: z.array(RecruitmentDetailResource),
  scheduleWarning: RecruitmentScheduleWarning,
  schedules: z.array(RecruitmentDetailSchedule),
  tags: z.array(TagSchema),
  targetYear: z.number(),
  title: z.string(),
  type: z.enum([
    RECRUITMENT_TYPE_KEYS.INTERN,
    RECRUITMENT_TYPE_KEYS.SELECTION,
    RECRUITMENT_TYPE_KEYS.EVENT,
    RECRUITMENT_TYPE_KEYS.JOIN_EVENT,
    RECRUITMENT_TYPE_KEYS.GSSKT_EVENT,
    RECRUITMENT_TYPE_KEYS.ETC,
  ]),
  typeV2: RecruitmentType,
  appendixes: z.array(
    z.object({
      id: z.number(),
      content: z.string(),
      createdAt: z.string(),
      modifiedAt: z.string(),
    }),
  ),
  isPreview: z.boolean(),
  canEdit: z.boolean(),
  modifiedAt: z.string(),
  displayEndAt: z.string(),
});

export const RecruitmentsSchema = z.array(RecruitmentSchema);

export const RecruitmentListSchema = z.object({
  recruitments: z.array(RecruitmentSchema),
  totalCount: z.number(),
  nextPage: z.number(),
});

export const RankingsSchema = z.object({
  id: z.number(),
  rank: z.number(),
  path: z.string(),
  title: z.string(),
  created: z.string(),
});

export const RecruitmentRankingsSchema = z.array(RankingsSchema);

export type RecruitmentDetailResponse = z.infer<
  typeof RecruitmentDetailResponseSchema
>;

export const GetRecruitmentWebSeminarsResponseSchema = z.array(
  z.object({
    id: z.number(),
    openDatetime: z.string(),
    closeDatetime: z.string(),
    youtubeVideoID: z.string(),
    title: z.string(),
    description: z.string(),
    thumbnailURL: z.string(),
    isPrMovie: z.boolean(),
    isFreeListing: z.boolean(),
    isOriginal: z.boolean(),
    serviceDefinitePeriodApplyItemOpen: z.string().nullable(),
    serviceDefinitePeriodApplyItemClose: z.string().nullable(),
  }),
);
export type GetRecruitmentWebSeminarsResponse = z.infer<
  typeof GetRecruitmentWebSeminarsResponseSchema
>;

const SimilarRecruitment = z.object({
  id: z.number(),
  title: z.string(),
  type: z.string(),
  detail_page_url: z.string(),
  job_types: z.array(GroupedJobType),
  recruiting_item_type: z.string(),
  can_entry: z.boolean(),
  entry_url: z.string(),
  company: z.object({
    id: z.number(),
    name: z.string(),
    logo_url: z.string(),
    industry: z.object({
      id: z.number(),
      name: z.string(),
    }),
  }),
});
export type SimilarRecruitment = z.infer<typeof SimilarRecruitment>;

export const GetSimilarRecruitmentListResponseScheme =
  z.array(SimilarRecruitment);
export type GetSimilarRecruitmentListResponse = z.infer<
  typeof GetSimilarRecruitmentListResponseScheme
>;

const RecommendedRecruitment = z.object({
  id: z.number(),
  title: z.string(),
  type: z.string(), // Z.enum(['client', 'archive', 'unknown'])
  detail_page_url: z.string(),
  order: z.number().optional(),
  job_types: z.array(GroupedJobType),
  recruiting_item_type: z.string(), // Z.enum(['intern', 'selection', 'event', 'jointEvent'])
  can_entry: z.boolean(),
  entry_url: z.string(),
  company: z.object({
    id: z.number(),
    name: z.string(),
    logo_url: z.string(),
    industry: z.object({
      id: z.number(),
      name: z.string(),
    }),
  }),
});
export type RecommendedRecruitment = z.infer<typeof RecommendedRecruitment>;

export const GetRecommendedRecruitmentListResponseSchema = z.array(
  RecommendedRecruitment,
);
export type GetRecommendedRecruitmentListResponse = z.infer<
  typeof GetRecommendedRecruitmentListResponseSchema
>;

export const GetRecommendedRecruitsByIndustrySchema = z
  .array(RecommendedRecruitment)
  .nullable();

export type GetRecommendedRecruitsByIndustryResponse = z.infer<
  typeof GetRecommendedRecruitsByIndustrySchema
>;

const RelatedCompanyRecruitment = z.object({
  recruitments: z.array(Recruitment),
  totalCount: z.number(),
  nextPage: z.number(),
});
export type RelatedCompanyRecruitment = z.infer<
  typeof RelatedCompanyRecruitment
>;

export const RelatedCompanyRecruitmentListResponseSchema = z.array(
  RelatedCompanyRecruitment,
);
export type RelatedCompanyRecruitmentListResponse = z.infer<
  typeof RelatedCompanyRecruitmentListResponseSchema
>;

export const PreviewCodeQueryParam = z.object({
  preview_code: z.string().optional(),
});

const InternalPreviewCodeCurrentUnivType = z.enum([
  'bachelor',
  'graduate',
  'doctor',
]);
export type InternalPreviewCodeCurrentUnivType = z.infer<
  typeof InternalPreviewCodeCurrentUnivType
>;

export const InternalPreviewCodeQueryParam = z.object({
  internal_preview_code: z.string().optional(),
  sex: z.string().optional(),
  bun_ri: z.string().optional(),
  university: z.string().optional(),
  current_univ_type: InternalPreviewCodeCurrentUnivType.optional(),
  resources: z.string().optional(),
});
export type InternalPreviewCodeQueryParam = z.infer<
  typeof InternalPreviewCodeQueryParam
>;
