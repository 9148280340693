import {
  type ResultWithOauthToken,
  type AppError,
  type Result,
  InvalidTokenError,
} from '@packages/utils';
import {
  useInfiniteQuery,
  useQuery,
  type UseInfiniteQueryResult,
  type UseQueryOptions,
} from '@tanstack/react-query';
import {type z} from 'zod';
import {parseCookies} from 'nookies';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';
import {
  DepartmentMasterResponse,
  IndustryCategoriesResponse,
  type ReportSchema,
  ReportTypesResponse,
  ReportsCountResponse,
  ReportsResponse,
  getEntrySheetSchema,
  getReportListSchema,
  getSelectionReportSchema,
  type ComapanyDepartmentMasterResponse,
} from '../entities/reports/schema';
import {getReportsFromPages} from '../factories/reports/getReportsFromPages';
import {sendApiRequest} from '../usecases/auth/sendApiRequest';
import {useIsSessionExpired} from '../usecases/auth/useIsSessionExpired';

/**
 * GetIndustryCategories
 */

export type IndustryCategoriesReturnValueType = Result<
  IndustryCategoriesResponse,
  AppError
>;
export type IndustryCategoriesResponse = z.infer<
  typeof IndustryCategoriesResponse
>;

type GetIndustryCategoriesArgs = {
  version?: string;
};

type UseGetIndustryCategoriesOptions = {
  config?: UseQueryOptions<IndustryCategoriesReturnValueType>;
} & GetIndustryCategoriesArgs;

export const getIndustryCategories = async ({
  version = DEFAULT_API_VERSION,
}: GetIndustryCategoriesArgs): Promise<IndustryCategoriesReturnValueType> => {
  const path = `/${version}/industry-categories`;
  const result = await defaultClient.get(path, IndustryCategoriesResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useIndustryCategories = ({
  config,
}: UseGetIndustryCategoriesOptions) => {
  return useQuery<IndustryCategoriesReturnValueType>({
    ...config,
    queryKey: ['industryCategories'],
    async queryFn() {
      return getIndustryCategories({});
    },
  });
};

/**
 * GetDepartmentMaster
 */

export type DepartmentMasterReturnValueType = Result<DepartmentMasterResponse>;
export type DepartmentMasterResponse = z.infer<typeof DepartmentMasterResponse>;

type GetDepartmentMasterArgs = {
  version?: string;
  queryParams?: {
    isPopular?: string;
  };
};

type UseGetDepartmentMasterOptions = {
  config?: UseQueryOptions<DepartmentMasterReturnValueType>;
} & GetDepartmentMasterArgs;

export const getDepartmentMaster = async ({
  version = DEFAULT_API_VERSION,
  queryParams,
}: GetDepartmentMasterArgs): Promise<DepartmentMasterReturnValueType> => {
  const path = createPathWithQuery(`/${version}/master/departments`, {
    ...queryParams,
  });
  const result = await defaultClient.get(path, DepartmentMasterResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useDepartmentMaster = ({
  config,
  queryParams,
}: UseGetDepartmentMasterOptions) => {
  return useQuery<DepartmentMasterReturnValueType>({
    ...config,
    queryKey: ['departmentMaster', queryParams],
    async queryFn() {
      return getDepartmentMaster({queryParams});
    },
  });
};

/**
 * GetReportsCounts
 */

export type ReportsCountReturnValueType = Result<ReportsCountResponse>;
export type ReportsCountResponse = z.infer<typeof ReportsCountResponse>;

type GetReportsCountArgs = {
  version?: string;
  queryParams: {
    company: string;
    types: string[];
    industryCategoryIDs: number[];
    departmentMasterIDs: number[];
  };
};

type UseGetReportsCountOptions = {
  config?: UseQueryOptions<ReportsCountReturnValueType>;
} & GetReportsCountArgs;

export const getReportsCount = async ({
  version = DEFAULT_API_VERSION,
  queryParams,
}: GetReportsCountArgs): Promise<ReportsCountReturnValueType> => {
  const path = createPathWithQuery(
    `/${version}/reports/count`,
    {...queryParams},
    'after',
  );
  const result = await defaultClient.get(path, ReportsCountResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useReportsCount = ({
  config,
  queryParams,
}: UseGetReportsCountOptions) => {
  return useQuery<ReportsCountReturnValueType>({
    ...config,
    queryKey: ['reportsCount', queryParams],
    async queryFn() {
      return getReportsCount({queryParams});
    },
  });
};

/**
 * GetReportTypes
 */

export type ReportTypesReturnValueType = Result<ReportTypesResponse>;
export type ReportTypesResponse = z.infer<typeof ReportTypesResponse>;

type GetReportTypesArgs = {
  version?: string;
};

type UseGetReportTypesOptions = {
  config?: UseQueryOptions<ReportTypesReturnValueType>;
} & GetReportTypesArgs;

export const getReportTypes = async ({
  version = DEFAULT_API_VERSION,
}: GetReportTypesArgs): Promise<ReportTypesReturnValueType> => {
  const path = `/${version}/reports/types`;
  const result = await defaultClient.get(path, ReportTypesResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useReportTypes = ({config}: UseGetReportTypesOptions) => {
  return useQuery<ReportTypesReturnValueType>({
    ...config,
    queryKey: ['reportTypes'],
    async queryFn() {
      return getReportTypes({});
    },
  });
};

/**
 * GetCompanyDepartmentMaster
 */

export type CompanyDepartmentMasterReturnValueType =
  Result<CompanyDepartmentMasterResponse>;
export type CompanyDepartmentMasterResponse = z.infer<
  typeof ComapanyDepartmentMasterResponse
>;

type GetCompanyDepartmentMasterArgs = {
  version?: string;
  companyId: number;
};

type UseGetCompanyDepartmentMasterOptions = {
  config?: UseQueryOptions<CompanyDepartmentMasterReturnValueType>;
} & GetCompanyDepartmentMasterArgs;

export const getCompanyDepartmentMaster = async ({
  version = DEFAULT_API_VERSION,
  companyId,
}: GetCompanyDepartmentMasterArgs): Promise<CompanyDepartmentMasterReturnValueType> => {
  const path = `/${version}/companies/${companyId}/departments/master`;
  const result = await defaultClient.get(path, DepartmentMasterResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useCompanyDepartmentMaster = ({
  config,
  companyId,
}: UseGetCompanyDepartmentMasterOptions) => {
  return useQuery<CompanyDepartmentMasterReturnValueType>({
    ...config,
    queryKey: ['companyDepartmentMaster', companyId],
    async queryFn() {
      return getCompanyDepartmentMaster({companyId});
    },
  });
};

/**
 * GetReports
 */
export type ReportType = z.infer<typeof ReportSchema>;
export type ReportsReturnValueType = Result<ReportsResponse>;
export type ReportsResponse = z.infer<typeof ReportsResponse>;

type GetReportsArgs = {
  version?: string;
  queryParams: {
    companyIDs?: number;
    types: string[];
    departmentMasterIDs: number[];
    limit: number;
    page: number;
    selectionReportIDs?: number[];
  };
};

type UseGetReportsOptions = {
  config?: UseQueryOptions<ReportsReturnValueType>;
} & GetReportsArgs;

export const getReports = async ({
  version = DEFAULT_API_VERSION,
  queryParams,
}: GetReportsArgs): Promise<ReportsReturnValueType> => {
  const path = createPathWithQuery(
    `/${version}/reports`,
    {...queryParams},
    'none',
  );
  const result = await defaultClient.get(path, ReportsResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useReports = ({config, queryParams}: UseGetReportsOptions) => {
  return useQuery<ReportsReturnValueType>({
    ...config,
    queryKey: ['reports', queryParams],
    async queryFn() {
      return getReports({queryParams});
    },
  });
};

export const useInfiniteReports = ({
  queryParams,
}: UseGetReportsOptions): UseInfiniteQueryResult<ReportsResponse, AppError> => {
  return useInfiniteQuery(
    [`reports`],
    async () => {
      const result = await getReports({queryParams});
      return getReportsFromPages(result);
    },
    {
      getNextPageParam: (lastPage) => lastPage?.page ?? 0,
    },
  );
};

export type GetEntrySheetResponse = z.infer<typeof getEntrySheetSchema>;
type ResultGetEntrySheet = ResultWithOauthToken<
  GetEntrySheetResponse,
  AppError
>;

type GetEntrySheetOptions = {
  entrySheetID: number;
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getEntrySheet = async ({
  entrySheetID,
  cookies,
  version = DEFAULT_API_VERSION,
}: GetEntrySheetOptions): Promise<ResultGetEntrySheet> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);

    const path = `/${version}/entrysheets/-/${entrySheetID}`;
    return defaultClient.get(path, getEntrySheetSchema);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    authRequired: false, // AccessTokenがない場合でもAPI取得できる
    allowReturnError: true,
  });

  return result;
};

type UseGetEntrySheetOptions = {
  config?: UseQueryOptions<ResultGetEntrySheet>;
} & Omit<GetEntrySheetOptions, 'cookies'>;

export const useGetEntrySheet = ({
  entrySheetID,
  version = DEFAULT_API_VERSION,
  config,
}: UseGetEntrySheetOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<ResultGetEntrySheet>({
    ...config,
    queryKey: ['useGetEntrySheet', entrySheetID],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getEntrySheet({entrySheetID, cookies, version});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

export type GetSelectionReportResponse = z.infer<
  typeof getSelectionReportSchema
>;
type ResultGetSelectionReport = Result<GetSelectionReportResponse, AppError>;

type GetSelectionReportOptions = {
  reportID: number;
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getSelectionReport = async ({
  reportID,
  cookies,
  version = DEFAULT_API_VERSION,
}: GetSelectionReportOptions) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);

    const path = `/${version}/selection-reports/-/${reportID}`;
    return defaultClient.get(path, getSelectionReportSchema);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    authRequired: false,
    allowReturnError: true,
  });

  return result;
};

type UseGetSelectionReportOptions = {
  config?: UseQueryOptions<ResultGetSelectionReport>;
} & Omit<GetSelectionReportOptions, 'cookies'>;

export const useGetSelectionReport = ({
  reportID,
  version = DEFAULT_API_VERSION,
  config,
}: UseGetSelectionReportOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<ResultGetSelectionReport>({
    ...config,
    queryKey: ['getSelectionReport', reportID],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getSelectionReport({reportID, cookies, version});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

export type GetReportListResponse = z.infer<typeof getReportListSchema>;
type ResultGetReportList = Result<GetReportListResponse, AppError>;

type ReportParamType =
  | 'entrysheet'
  | 'selection'
  | 'spring'
  | 'summer'
  | 'autumn'
  | 'winter'
  | 'etc';

type GetReportListOptions = {
  version?: string;
  queryParams: {
    types?: ReportParamType[];
    companyIDs?: number;
    isGetOffer?: boolean;
    limit?: number;
    page?: number;
  };
};

export const getReportList = async ({
  queryParams,
  version = DEFAULT_API_VERSION,
}: GetReportListOptions) => {
  const convertedQueryParams = Object.fromEntries(
    Object.entries(queryParams).map(([k, v]) => [
      k,
      Array.isArray(v) ? v : String(v),
    ]),
  );
  const path = createPathWithQuery(
    `/${version}/reports`,
    convertedQueryParams,
    'none',
  );
  const result = await defaultClient.get(path, getReportListSchema);
  return result;
};

type UseGetReportListOptions = {
  config?: UseQueryOptions<ResultGetReportList, AppError>;
} & GetReportListOptions;

export const useGetReportList = ({
  queryParams,
  version = DEFAULT_API_VERSION,
  config,
}: UseGetReportListOptions) => {
  return useQuery<ResultGetReportList, AppError>({
    ...config,
    queryKey: ['getReportList', queryParams],
    async queryFn() {
      return getReportList({queryParams, version});
    },
  });
};
